import { mapGetters, mapMutations } from 'vuex'
import mixins from '@/mixins'

export default {
  name: 'news-view',
  mixins: [mixins],
  computed: {
    ...mapGetters({
      newsGeneralLoading: 'newsGeneral/newsGeneralLoading',
      contentLoading: 'newsContent/contentLoading',
      seoLoading: 'newsSeo/seoLoading'
    }),
    isCreateRoute () {
      return this.$route.name.includes('.create')
    }
  },
  methods: {
    ...mapMutations({
      resetGeneral: 'newsGeneral/GENERAL_SET_DATA',
      resetSeo: 'newsSeo/SEO_SET_DATA',
      resetContent: 'newsContent/CONTENT_SET_DATA'
    })
  },
  destroyed () {
    this.resetGeneral(null)
    this.resetSeo(null)
    this.resetContent(null)
  }
}
